import { BigNumberish, utils } from "ethers"


export const parseAmountToken = (amount: string | number, decimal: number = 18) => {
  return utils.parseUnits(amount.toString(), decimal)
}
export const formatAmountToken = (amount: BigNumberish, decimal: number = 18) => {
  return utils.formatUnits(amount, decimal)
}


export function stringDateToUTCDate(dateStr: string) {
  const inputDate = new Date(dateStr);
  return new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000);
}


export function equalAddress(addressA: string, addressB: string) {
  return addressA.toLowerCase() === addressB.toLowerCase();
}
