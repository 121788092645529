
import "extensionsjs/lib"
import React from "react";
import ReactDOM from "react-dom";
import './styles/index.less';
import 'react-quill/dist/quill.snow.css';
import AppRoot from "./AppRoot";
import reportWebVitals from "./reportWebVitals";

window.addEventListener('error', function (e) {
  console.error(e.message);
  // prevent React's listener from firing
  e.stopImmediatePropagation();
  // prevent the browser's console error message
  e.preventDefault();
});

ReactDOM.render(
  <AppRoot />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
