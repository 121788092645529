export const zhCN_title = {
  'title.login': '登录',
  'title.dashboard': '首页',
  'title.documentation': '文档',
  'title.guide': '引导页',
  'title.permission.route': '路由权限',
  'title.permission.button': '按钮权限',
  'title.permission.config': '权限配置',
  'title.account': '个人设置',
  'title.notFount': '404',
};
