import { rootApiService } from ".";


const subdomain = "nftfeed"
const Enpoint = {
  users: `api/public/zealy/users`,
  claimedQuests: `api/public/zealy/claimed-quests`,
}

class ZealyConnector {
  async getUserInfoByWalletAddress(params: { ethAddress: string }) {
    return rootApiService.get(Enpoint.users, {
      ...params,
      subdomain
    })
  }


  async claimedQuests(params: { userId: string, questId: string }) {
    return rootApiService.get(Enpoint.claimedQuests, {
      ...params,
      subdomain
    })
  }

}
export default new ZealyConnector()
