import React, { useState } from 'react';
import { Alert, Button, Checkbox, Col, Form, Input, Layout, Row, Spin, Image, } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import BannerLogo from "~/assets/logo/banner_logo_feed.png"
import Banner from "~/assets/logo/banner.png"

import Title from 'antd/es/typography/Title';

const CloseView: React.FC = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  return (
    <Spin tip="Loading..." spinning={isLoading}  >

      <Layout
        style={{
          justifyContent: 'center',
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >

        <Row
          gutter={[24, 0]}
          className='login'
          style={{
            width: "100%",
            height: "100vh",
            // background: "#100F1E",

            backgroundImage: `url(${Banner})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >

          <Col xs={24} sm={24} md={24} lg={14} xl={12} style={{ textAlign: "center" }}>

            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >

              <Row gutter={[24, 2]} justify={"center"} style={{ paddingTop: "50px" }}>

                <Col span={24} >

                  <Title
                    style={{
                      color: "#fff",
                      fontFamily: "Jost-Bold",
                      textAlign: "center",
                      fontSize: "62px",
                    }}
                    level={2}
                  >
                    NFTFEED CLOSE BETA TESTNET
                  </Title>
                </Col>



              </Row>

            </Form>

          </Col>

          <Col
            xs={24} sm={24} md={24} lg={10} xl={12}
            style={{
              textAlign: "center",
              paddingTop: "0%"
            }}
          >

            <Image
              src={BannerLogo}
              preview={false}
              style={{
                objectFit: "contain",
                height: "915px",
              }}
            />

          </Col>

        </Row>

      </Layout >

    </Spin >

  )
}

export default CloseView;
