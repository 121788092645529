import { FC } from "react";
import { useNavigate, useRoutes } from "react-router";
import { RouteObject } from 'react-router';
import { navService, toastService } from "~/services";
import { mainRouter } from "./main/main.router";
import MainLayout from "~/layouts/MainLayout";
import WhitelistView from "./main/WhitelistView";
import CloseView from "./main/ColseView";

const appRouter: RouteObject[] = [
  // {
  //   path: '',
  //   element: <MainLayout />,
  //   children: mainRouter as RouteObject[]
  // },
  {
    path: "",
    element: <CloseView />,
  },
  // {
  //   path: "whitelist",
  //   element: <WhitelistView />,
  // },

]
const AppRouter: FC = ({ ...props }) => {
  const navigate = useNavigate();
  navService.initRefNav(navigate);
  toastService.initNavigation(navigate);
  const element = useRoutes(appRouter);
  return element;
};

export default AppRouter;

