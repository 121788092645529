
import "./index.less";

const BaseView = ({ children }) => (
  <div className="base-view" style={{ padding: 10 }}>
    {children}
  </div>
)

export default BaseView;
