/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { String, StringInterface } from "../String";

const _abi = [
  {
    inputs: [
      {
        internalType: "string",
        name: "s1",
        type: "string",
      },
      {
        internalType: "string",
        name: "s2",
        type: "string",
      },
    ],
    name: "compare",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "s1",
        type: "string",
      },
      {
        internalType: "string",
        name: "s2",
        type: "string",
      },
    ],
    name: "concatenate",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

const _bytecode =
  "0x6102d861003a600b82828239805160001a60731461002d57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600436106100405760003560e01c8063266001d3146100455780633a96fdd71461006e575b600080fd5b6100586100533660046101b9565b610091565b604051610065919061024d565b60405180910390f35b61008161007c3660046101b9565b6100bd565b6040519015158152602001610065565b606082826040516020016100a6929190610280565b604051602081830303815290604052905092915050565b6000816040516020016100d091906102af565b60405160208183030381529060405280519060200120836040516020016100f791906102af565b6040516020818303038152906040528051906020012014905092915050565b634e487b7160e01b600052604160045260246000fd5b600082601f83011261013d57600080fd5b813567ffffffffffffffff8082111561015857610158610116565b604051601f8301601f19908116603f0116810190828211818310171561018057610180610116565b8160405283815286602085880101111561019957600080fd5b836020870160208301376000602085830101528094505050505092915050565b600080604083850312156101cc57600080fd5b823567ffffffffffffffff808211156101e457600080fd5b6101f08683870161012c565b9350602085013591508082111561020657600080fd5b506102138582860161012c565b9150509250929050565b60005b83811015610238578181015183820152602001610220565b83811115610247576000848401525b50505050565b602081526000825180602084015261026c81604085016020870161021d565b601f01601f19169190910160400192915050565b6000835161029281846020880161021d565b8351908301906102a681836020880161021d565b01949350505050565b600082516102c181846020870161021d565b919091019291505056fea164736f6c6343000809000a";

export class String__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<String> {
    return super.deploy(overrides || {}) as Promise<String>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): String {
    return super.attach(address) as String;
  }
  connect(signer: Signer): String__factory {
    return super.connect(signer) as String__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): StringInterface {
    return new utils.Interface(_abi) as StringInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): String {
    return new Contract(address, _abi, signerOrProvider) as String;
  }
}
