import viVN from "antd/es/locale/vi_VN";
import enUS from "antd/es/locale/en_US";
import 'moment/locale/vi';

export const LOCALE_RESOURCE = {
    vi: {
        key: "vi_VN",
        momentValue: "vi",
        antdValue: viVN
    },
    en: {
        key: "en_US",
        momentValue: "en",
        antdValue: enUS
    }
}
export type LocaleKey = keyof typeof LOCALE_RESOURCE;
