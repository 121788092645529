import { IEnvConfig } from "../dto";


const config: IEnvConfig = {
  name: "PRODUCTION",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://i198e9817g.execute-api.ap-southeast-1.amazonaws.com/prod/mvs-dapp"
    },
  },
  applyChainIds: [97, 80001]
}

export default config;
