import { useCallback, useEffect, useState } from "react"
import zealyConnector from "~/services/@common/zealy.connector"
import { useQuest } from "./useQuest"
import { configEnv } from "~/@config";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { QuestPool, QuestPool__factory } from "~/abi";
import { formatAmountToken } from "~/common/block-chain.helper";
import { toastService } from "~/services";


const { customSMC } = configEnv();

export const useQuestDetail = ({ questId }) => {


  const { userId, xp } = useQuest();
  const { account, chainId, provider } = useWeb3React();

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    amountClaimed: '0',
    isClaimed: false
  })
  const [questData, setQuestData] = useState({
    claimed: false,
    xp: 0
  })

  const loadDataQuest = useCallback(async () => {
    const res = await zealyConnector.claimedQuests({
      questId,
      userId
    });
    setQuestData({
      ...res
    })
  }, [questId, userId]);

  const loadDataUser = useCallback(async () => {
    try {
      const { BUSD = "", QUEST_POOL } = customSMC[chainId];
      const poolCt = new Contract(
        QUEST_POOL,
        QuestPool__factory.abi,
        provider
      ) as QuestPool;


      const { amountClaimed, isClaimed } = await poolCt.getUserInfoByQuest(questId, account);

      setUserData({
        amountClaimed: formatAmountToken(amountClaimed),
        isClaimed
      })

    } catch (error) {
      console.log(error);

    }
  }, [account, chainId, provider, questId]);


  useEffect(() => { loadDataQuest() }, [loadDataQuest]);


  useEffect(() => { loadDataUser() }, [loadDataUser])

  const claim = useCallback(async () => {
    setIsLoading(true);
    try {
      const { BUSD = "", QUEST_POOL } = customSMC[chainId];
      const poolCt = new Contract(
        QUEST_POOL,
        QuestPool__factory.abi,
        provider
      ) as QuestPool;

      const signer = provider.getSigner(account);
      const { transactionHash } = await (await poolCt.connect(signer).claim(questId)).wait();
      toastService.success(transactionHash);

      loadDataUser();
    } catch (error) {
      console.log(error);

    }
    setIsLoading(false);
  }, [account, chainId, loadDataUser, provider, questId]);



  return {
    isLoading,
    questData,
    userData,
    claim,
    xp,
  }
}
