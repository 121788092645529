

import { Buffer } from "buffer";
import { FC, memo, useEffect } from 'react';
import App from './App';
import { ThemeStoreProvider } from './stores/themeStore';
import { BrowserRouter } from 'react-router-dom';
import { LayoutConfigProvider } from './stores/layoutConfig';
import { Web3ReactProvider } from '@web3-react/core';
import { evmConnectors } from "./@web3-config/evm";
import { AuthStoreProvider } from "./stores/authStore";

interface IProps {

}

const composeProviders = (...providers: any[]) => (Child) => (props) => (
  providers.reduce((acc, Provider) => (
    <Provider>
      {acc}
    </Provider>
  ), <Child {...props} />)
)
const WrappedApp = composeProviders(
  ThemeStoreProvider,
  LayoutConfigProvider,
  AuthStoreProvider,
)(App)

const AppRoot: FC<IProps> = memo(() => {
  if (!window.Buffer) window.Buffer = Buffer;

  return (
    <BrowserRouter >
      <Web3ReactProvider connectors={evmConnectors}>
        <WrappedApp />
      </Web3ReactProvider>
    </BrowserRouter>
  )
})

export default AppRoot;
