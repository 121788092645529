
import { HomeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { v4 } from 'uuid';
import { IRouter } from '~/routers';
import HomeView from './HomeView';


export const mainRouter: IRouter[] = [

  {
    path: "",
    key: v4(),
    element: <HomeView />,
    title: "Home",
    isMenu: true,
    icon: <HomeOutlined />,
  },


]
