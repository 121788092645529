
import { NavigateFunction } from "react-router"

export class NavService {
  public navigate: NavigateFunction;
  initRefNav(navigate: NavigateFunction) {
    this.navigate = navigate;
  }
}
export const navService = new NavService();
