import { } from "~/dto";
import { rootApiService } from "./@common";
import { LoginReq, UserSessionDto } from "~/dto/auth.dto";

const Enpoint = {
  authenticate: "/api/admin/authenticate".trim(),
  login: "/api/admin/login".trim(),
}

export class AuthService {
  async authenticate() {
    return rootApiService.post<UserSessionDto>(Enpoint.authenticate);
  }

  login(body: LoginReq) {
    return rootApiService.post<UserSessionDto>(Enpoint.login, body)
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
