import { IEnvConfig } from "../dto";

const config: IEnvConfig = {
  name: "QA",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://i198e9817g.execute-api.ap-southeast-1.amazonaws.com/prod/mvs-dapp"
    }
  },
  applyChainIds: [84531, 97],
  customSMC: {
    97: {
      VAULT_FACTORY: "0x9DE8EEF1aB1737ce8382282c7fC3c32EeFC252cB".trim(),
      BUSD: "0x13b123a205b607Daa99a9D8E21E92d05ad4dFB9F".trim(),
      QUEST_POOL: "".trim(),
    },
    84531: {
      VAULT_FACTORY: "0x00EAEAEc82BAF99B3aFdc60930a937696708f730".trim(),
      BUSD: "0x15ca7e1203F25e4a56D86faCB6c4715bbA52e795".trim(),
      QUEST_POOL: "0xca8B840932c0Aa34B9E425774c15074B56877fF2".trim(),
    }
  }
}

export default config;
